import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setItems } from '../../redux/slices/cartSlice';

function PizzaBlock({ title, price, imageUrl, sizes, types, id }) {
  const dispatch = useDispatch();
  const pizzaCards = useSelector((store) => store.cart.items);
  const [sizeIndx, setSizeIndx] = React.useState(0);
  const [typeIndx, setTypeIndx] = React.useState(0);
  const typeNames = ['тонкое', 'традиционное'];

  // сначала фильтруем элементы по id, потом суммируем их count
  const pizzaCount = pizzaCards.filter((el) => el.id === id).reduce((sum, el) => sum + el.count, 0);

  const addToCart = () => {
    const cartItem = {
      id,
      title,
      price,
      imageUrl,
      size: sizes[sizeIndx],
      type: types[typeIndx],
      count: 1,
    };
    dispatch(setItems(cartItem));
  };

  return (
    <div className="pizza-block">
      <img className="pizza-block__image" src={imageUrl} alt="Pizza" />
      <h4 className="pizza-block__title">{title}</h4>
      <div className="pizza-block__selector">
        <ul>
          {types.map((typeId) => (
            <li
              key={typeId}
              onClick={() => setTypeIndx(typeId)}
              className={typeIndx === typeId || types.length === 1 ? 'active' : ''}>
              {typeNames[typeId]}
            </li>
          ))}
        </ul>
        <ul>
          {sizes.map((size, i) => (
            <li
              key={size}
              onClick={() => setSizeIndx(i)}
              className={sizeIndx === i ? 'active' : ''}>
              {size} см.
            </li>
          ))}
        </ul>
      </div>
      <div className="pizza-block__bottom">
        <div className="pizza-block__price">от {price} ₴</div>
        <button onClick={addToCart} className="button button--outline button--add">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.8 4.8H7.2V1.2C7.2 0.5373 6.6627 0 6 0C5.3373 0 4.8 0.5373 4.8 1.2V4.8H1.2C0.5373 4.8 0 5.3373 0 6C0 6.6627 0.5373 7.2 1.2 7.2H4.8V10.8C4.8 11.4627 5.3373 12 6 12C6.6627 12 7.2 11.4627 7.2 10.8V7.2H10.8C11.4627 7.2 12 6.6627 12 6C12 5.3373 11.4627 4.8 10.8 4.8Z"
              fill="white"
            />
          </svg>
          <span>Добавить</span>
          <i>{pizzaCount}</i>
        </button>
      </div>
    </div>
  );
}

export default PizzaBlock;
